import React from "react"
import { Jumbotron, Row, Col } from "react-bootstrap"

import welcomeStyles from "./welcome.module.css"

import Logo from "./logo"

const Welcome = () => (
  <div className="Welcome">
    <Jumbotron className={welcomeStyles.jumbotron}>
      <Row className="align-items-center">
        <Col>
          <h1 className={`display-4 ${welcomeStyles.display4}`}>
            Herzlich Willkommen <br /> beim Schützenverein 1961 Gambach e.V.
          </h1>
          <p className="lead mb-0">
            Mitglied des Hessischen Schützenverbandes im Deutschen Schützenbund
          </p>
        </Col>
        <Col xs="auto">
          <Logo />
        </Col>
      </Row>
    </Jumbotron>
  </div>
)

export default Welcome
