import React from "react"
import { Container, Image } from "react-bootstrap"
import { Link } from "gatsby"

import Image01 from "../images/home/image1.jpg"
import Image02 from "../images/home/image2.jpg"
import Image03 from "../images/home/image3.jpg"
import Image04 from "../images/home/image4.jpg"
import Image05 from "../images/home/image5.jpg"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Welcome from "../components/welcome"

const IndexPage = () => (
  <Layout>
    <SEO title="Start" lang="de" />
    <Container>
      <Welcome />
      <p className="font-weight-bold">
        Liebe Mitglieder, liebe Freunde des Schießsports,
      </p>
      <p>
        zu unserer großen Freude, konnten wir im Jahr 2023 alle geplanten
        Veranstaltungen durchführen.
      </p>

      <p>
        In der <b>Ordentlichen Mitgliederversammlung</b> am 25. Februar wurde
        ein neuer Vorstand gewählt. Zum neuen Vorsitzenden wurde Heiko Lippert
        einstimmig gewählt. Er wird nun zusammen mit dem Gesamtvorstand die
        Geschicke des Vereins führen.
      </p>

      <Image src={Image01} className="d-block mx-auto mb-3" width={300} />

      <p>
        Nach zweijähriger Corona-Pause fand das{" "}
        <b>41. Wanderpokalschießen für Ortsvereine der Stadt Münzenberg</b>{" "}
        wieder statt. Das Trainingsschießen startete am 28. März und endete am
        20. April. Die Wettkämpfe fanden dann am 21. und 22. April statt. Die
        Pokalverleihung fand im Anschluss an die Wettkämpfe im Schützenhaus
        statt. Hier sagen wir herzlichen Dank an die vielen Teams, welche sich
        am Pokalschießen beteiligten.
      </p>

      <Image src={Image03} className="d-block mx-auto mb-3" width={500} />

      <p>
        Anlässlich der 1225-Jahrfeier von Gambach beteiligte sich der
        Schützenverein mit einer kleinen Präsentation im Hof der Familie Fink in
        der Kirchgasse. Hier präsentierte sich der Verein mit verschiedenen
        Schautafeln über die Historie, sowie auch einige Luftdruckgewehre der
        neuesten Ausführung. Ebenso wurde ein Laserschießen angeboten, welches
        großen Anklang fand.
      </p>

      <Image src={Image02} className="d-block mx-auto mb-3" width={450} />

      <p>
        Am 5. August konnten wir unser <b>Sommerfest</b> mit unserem
        alljährlichen Königsschießen durchführen. Die Veranstaltung war wieder
        hervorragend besucht. Bei leckeren Kuchen und köstlichen Speisen am
        Abend verbrachte die Schützenfamilie einen wunderschönen Tag.
      </p>

      <Image src={Image04} className="d-block mx-auto mb-3" width={450} />

      <p>
        Zum Jahresabschluss fand am 9. Dezember unser Nikolausschießen statt.
        Nach einem gemeinsamen Abendessen, fand das traditionelle Schießen der
        Jagd- und Glücksscheiben statt.
      </p>

      <Image src={Image05} className="d-block mx-auto mb-3" width={450} />

      <p>
        Aktuell sind die <b>Termine</b> für das Jahr <b>2024</b> aktualisiert.
        Bitte schon heute vormerken.
      </p>

      <p>Bleibt gesund!</p>

      <p>Lars Wächter - Schriftführer</p>

      <hr />

      <p>
        <b>Organisatorisches:</b>
      </p>

      <p>
        Unsere Heim-Wettkämpfe werden in unserem Schützenhaus ausgetragen, hier
        ein{" "}
        <a
          href="https://goo.gl/maps/UAwPtTRfnuxY4FP67"
          target="_blank"
          rel="noreferrer"
        >
          Link zur Karte
        </a>{" "}
        von Google Maps als Anfahrtsbeschreibung.
      </p>

      <p>
        Ältere Berichte sowie die Abschlusstabellen vom Wanderpokalschießen für
        Vereine der Stadt Münzenberg und Berichte zu den Mitgliederversammlungen
        finden Sie in unserem <Link to="/archiv">Archiv</Link>.
      </p>
      <p>
        Wenn Sie sich für aktuelle Infos zu Rundenwettkämpfen, Meisterschaften
        und Terminen interessieren, finden Sie diese auf den Seiten des
        Schützenkreises 01 Friedberg.
      </p>
    </Container>
  </Layout>
)

export default IndexPage
